<template>
  <div class="footer">
    <div class="pcf">
      <div class="item">
        <img src="../../assets/images/afront/logo/thirdparty/slot/7mojosgame.png">
      </div>
      <div class="item">
        <img src="../../assets/images/afront/logo/thirdparty/slot/blueprintgame.png">
      </div>
      <div class="item">
        <img src="../../assets/images/afront/logo/thirdparty/slot/midasgame.png">
      </div>
      <div class="item">
        <img src="../../assets/images/afront/logo/thirdparty/slot/cq9.png">
      </div>
      <div class="item">
        <img src="../../assets/images/afront/logo/thirdparty/casino/skywind.png">
      </div>
      <div class="item">
        <img src="../../assets/images/afront/logo/thirdparty/casino/wm.png">
      </div>
      <div class="item">
        <img src="../../assets/images/afront/logo/thirdparty/casino/microgamingca.png">
      </div>

      <div class="item">
        <img src="../../assets/images/afront/logo/thirdparty/casino/evolution.png">
      </div>
      <div class="item">
        <img src="../../assets/images/afront/logo/thirdparty/casino/dowin.png">
      </div>

      <div class="item">
        <img src="../../assets/images/afront/logo/thirdparty/slot/evoplaygame.png">
      </div>
      <div class="item">
        <img src="../../assets/images/afront/logo/thirdparty/slot/1x2game.png">
      </div>
      <div class="item">
        <img src="../../assets/images/afront/logo/thirdparty/slot/bbingame.png">
      </div>
    </div>
    <div class="mobilef">
      <div class="item">
        <i class="iconfont icon-chongzhi"></i>
        <span>입금</span>
      </div>
      <div class="item">
        <i class="iconfont icon-tikuan"></i>
        <span>출금</span>
      </div>
      <div class="item">
        <i class="iconfont icon-shouye"></i>
        <span>메인</span>
      </div>
      <div class="item">
        <i class="iconfont icon-kefu"></i>
        <span>문의</span>
      </div>
      <div class="item">
        <i class="iconfont icon-message"></i>
        <span>쪽지</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Footer",
  components: {},
  data() {
    return {}
  },

  methods: {},
  created() {

  },
  watch: {}
}
</script>

<style scoped>

</style>