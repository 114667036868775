<template>

  <div class="rightwarp" :class="{'activeRightMenu':activeRightMenu}">
    <div class="content" style="background-color: aliceblue;">
      <div style="height: 30px">Right Start</div>
      <div style="height: 330px;background-color: #f5af53">11</div>
      <div style="height: 330px;background-color: #f8ab45">22</div>
      <div style="height: 330px;background-color: #f5c689">33</div>
      <div style="height: 330px;background-color: #f5900c">44</div>
      <div style="height: 330px;background-color: #fac683">55</div>
      <div style="height: 330px;background-color: #f5af53">66</div>
      <div style="height: 30px">Right End</div>
    </div>
    <!--      <div class="custom-vertical-scrollbar" ref="vertical">-->
    <!--        <div class="custom-vertical-indicator"></div>-->
    <!--      </div>-->
  </div>

</template>

<script>
import BScroll from "better-scroll";
import MouseWheel from '@better-scroll/mouse-wheel'
import ScrollBar from '@better-scroll/scroll-bar'

BScroll.use(MouseWheel)
BScroll.use(ScrollBar)
export default {
  name: "Right",
  components: {},
  data() {
    return {
      activeRightMenu: false,
    }
  },
  methods: {
    initScroll() {
      let wrapper = document.querySelector(".rightwarp");
      const scroll = new BScroll(wrapper, {
        click: true,
        freeScroll: true,
        mouseWheel: true,
        scrollbar: {
          customElements: [this.$refs.vertical],
          fade: false,
          interactive: true,
          scrollbarTrackClickable: true
        }
      })
    }
  },
  created() {
    setTimeout(() => {
      this.initScroll()
    }, 500);
    this.$bus.$on('activeRightMenu', (status) => {
      this.activeRightMenu = status
    })

  },
  watch: {
    game: {
      handler(newVal) {
      },
      immediate: true,
      deep: false
    },
  }
}
</script>

<style scoped>
.activeRightMenu {
  transform: translateX(0%) !important;
  transition: 0.3s transform;
}
</style>