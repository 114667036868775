<template>

    <div class="leftwarp" :class="{'activeLeftMenu':activeLeftMenu}">
      <div class="content" style="background-color: aliceblue;">
        <div>
          <div style="height: 30px">Left Start</div>
          <div style="height: 330px;background-color: #f5af53">11</div>
          <div style="height: 330px;background-color: #f8ab45">22</div>
          <div style="height: 330px;background-color: #f5c689">33</div>
          <div style="height: 330px;background-color: #f5900c">44</div>
          <div style="height: 330px;background-color: #fac683">55</div>
          <div style="height: 330px;background-color: #f5af53">66</div>
          <div style="height: 30px">왼쪽 End</div>

        </div>

      </div>
<!--      <div class="custom-vertical-scrollbar" ref="vertical">-->
<!--        <div class="custom-vertical-indicator"></div>-->
<!--      </div>-->
  </div>

</template>

<script>
import BScroll from "better-scroll";
import MouseWheel from '@better-scroll/mouse-wheel'
import ScrollBar from '@better-scroll/scroll-bar'
BScroll.use(MouseWheel)
BScroll.use(ScrollBar)
export default {
  name: "Left",
  components: {},
  data() {
    return {
      activeLeftMenu:false,
    }
  },
  methods: {

  },
  created() {
    this.$bus.$on('activeLeftMenu', (status)=>{
      this.activeLeftMenu = status
    })
  },
  watch: {
    game: {
      handler(newVal) {
      },
      immediate: true,
      deep: false
    },
  }
}
</script>

<style scoped>
.activeLeftMenu{
  transform: translateX(0%) !important;
  transition: 0.3s transform;
}
</style>